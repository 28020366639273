<template>
  <div class="article">
    <div v-if="cateDetail.image" class="banner">
      <img :src="cateDetail.image" alt="" />
    </div>
    <div class="main">
      <div class="menu">
        <div class="menu-header">{{ parentCateDetail.name }}</div>
        <ol class="menu-list">
          <li
            class="menu-list-item"
            :class="{ active: item.id == catId }"
            v-for="item in parentCateDetail.sub_cat"
            :key="item.id"
            @click="handleToCate(item.id)"
          >
            {{ item.name }}
          </li>
        </ol>
      </div>
      <div class="content">
        <div class="content-list">
          <div class="list-header">
            <div class="title">{{ cateDetail.name }}</div>
            <div class="position">
              当前位置：首页 > <span>{{ cateDetail.name }}</span>
            </div>
          </div>
          <template v-if="type === 'category'">
            <span v-if="resultList.length === 0" class="empty">暂无数据</span>
            <template v-else>
              <a
                href="javascript: void(0)"
                class="list-item"
                v-for="item in resultList"
                :key="item.id"
                @click="handleToDetail(item.id)"
              >
                <span class="content">{{ item.title }}</span>
                <span class="date">{{
                  $format(item.cdatetime, "YYYY-MM-DD")
                }}</span>
              </a>
            </template>
            <el-pagination
              class="pager"
              background
              layout="prev, pager, next"
              :page-count="maxPage"
              @prev-click="fetchList"
              @next-click="fetchList"
              @current-change="fetchList"
            >
            </el-pagination>
          </template>
          <div v-else class="text-content">
            <div class="text-main" v-html="articleDetail.content"></div>
            <div class="attach-file" v-if="articleDetail.source_file">
              附件：
              <a :href="articleDetail.source_file">{{
                articleDetail.source_file
              }}</a>
            </div>
            <!-- <div class="btns">
              <div class="btn-prev">上一篇</div>
              <div class="btn-prev">下一篇</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="lecture-list">
        <van-list
          v-model="loading"
          class="list-content"
          :finished="finished"
          @load="fetchList"
        >
          <div
            v-for="item in resultList"
            :key="item.id"
            class="list-item"
            @click="$router.push(`/web/events/${item.id}`)"
          >
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.description }}</div>
            <div class="date">{{ item.cdatetime }}</div>
          </div>
        </van-list>
      </div> -->
  </div>
</template>

<script>
import { Pagination } from "element-ui";
import indexApis from "@/apis/index";

export default {
  name: "Article",
  components: {
    "el-pagination": Pagination
  },
  data() {
    return {
      page: 0,
      maxPage: 0,
      finished: false,
      loading: false,
      resultList: [],
      catId: "",
      detailId: "",
      cateDetail: {},
      parentCateDetail: {},
      articleDetail: {},
      type: "",
      search_text: ""
    };
  },
  async created() {
    // this.fetchList();
    this.init();
  },
  methods: {
    async init() {
      const { id, cat_id, search_text } = this.$route.query;
      this.resultList = [];
      this.articleDetail = {};
      if (search_text) {
        this.search_text = search_text;
        this.cateDetail = {
          name: "搜索结果"
        };
        this.parentCateDetail = {
          name: "搜索结果"
        };
        this.type = "category";
        this.fetchList();
        return;
      }
      if (id) {
        this.detailId = id;
        this.type = "detail";
        await this.fetchDetail();
        this.catId = this.articleDetail.cat_id;
      } else {
        this.type = "category";
        this.catId = cat_id;
        this.fetchList();
      }
      this.fetchCate();
    },

    async fetchCate() {
      try {
        const {
          data: { article_cat }
        } = await indexApis.indexArticleCate({ id: this.catId });
        this.cateDetail = article_cat;

        if (article_cat.parent_id) {
          const {
            data: { article_cat: parentArticleCate }
          } = await indexApis.indexArticleCate({ id: article_cat.parent_id });
          this.parentCateDetail = parentArticleCate;
        } else {
          this.parentCateDetail = article_cat;
        }
      } catch (error) {
        console.log(error);
      }
    },
    
    async fetchList(page) {
      this.page = page;

      try {
        const {
          data: { result, max_page }
        } = await indexApis.indexList({
          page: this.page,
          cat_id: this.catId,
          search_text: this.search_text
        });
        this.maxPage = max_page;
        this.resultList = result;
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    async fetchDetail() {
      try {
        const {
          data: { article_info }
        } = await indexApis.indexDetail({ id: this.detailId });
        this.articleDetail = article_info;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },

    handleToCate(id) {
      if (this.type === "category" && id == this.catId) return;
      this.$router.push(`/web/article?cat_id=${id}`);
      // this.init();
    },

    handleToDetail(id) {
      if (this.type === "detail" && id == this.detailId) return;
      this.$router.push(`/web/article?id=${id}`);
      // this.init();
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.query !== oldVal.query) {
        this.init();
      }
    }
  }
};
</script>

<style lang="scss" scope>
.article {
  margin: 0 auto;
  width: 1200px;

  .banner {
    height: 180px;
    width: 1200px;
    background-color: #ddd;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .main {
    // display: flex;
    // justify-content: space-between;
    margin-top: 30px;

    .menu {
      width: 290px;
      background-color: #fff;
      display: inline-block;
      vertical-align: top;

      .menu-header {
        height: 102px;
        width: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        color: #333333;
        line-height: 31px;
      }

      .menu-list {
        width: 290px;
        padding: 0;

        .menu-list-item {
          cursor: pointer;
          height: 42px;
          width: 290px;
          color: #333333;
          font-size: 18px;
          line-height: 42px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 16px;
          box-sizing: border-box;

          &::before {
            content: "•";
            display: inline;
          }

          &.active {
            color: #fff;
            background: #ca0a15;
          }
        }
      }
    }

    .content {
      background-color: #fff;
      width: 880px;
      display: inline-flex;
      flex-flow: column nowrap;
      vertical-align: top;
      margin-left: 30px;

      .list-header {
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-bottom: 1px solid #ededed;

        .title {
          font-size: 22px;
          color: #333333;
          line-height: 31px;
          display: flex;
          align-items: center;

          &::before {
            content: "";
            display: block;
            width: 3px;
            height: 23px;
            background: #ca0a15;
            margin-right: 10px;
          }
        }

        .position {
          font-size: 14px;
          color: #8f8f8f;

          span {
            color: #333333;
          }
        }
      }

      .empty {
        display: block;
        text-align: center;
        padding: 20px;
      }

      .list-item {
        color: #333;
        font-size: 14px;
        line-height: 48px;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin: 0 20px;
        margin-right: 20px;
        margin-left: 20px;
        border-bottom: 1px solid #e6e6e6;

        .content {
          display: inline-block;
          width: calc(100% - 56px);
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 0;

          &::before {
            content: "‧";
            line-height: 32px;
            display: inline-block;
          }
        }

        .date {
          color: #8f8f8f;
          float: right;
        }
      }
    }

    .text-content {
      padding: 20px;
      font-size: 16px;
      color: #333333;
    }

    .btns {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      div {
        margin: 0 20px;
        color: #ca0a15;
        cursor: pointer;
      }
    }

    .pager {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
